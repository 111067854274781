import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseHardwareDisplay from '~/nasa_ui/base/BaseHardwareDisplay';
import { InventoryUnitTypeDisplay } from '~/nasa_ui/types';
import { InventoryMethodDisplay } from '~/nasa_ui/types/enums/hardware';
let AHDQuantityColumn = class AHDQuantityColumn extends BaseHardwareDisplay {
    availableQuantity;
    installedQuantity;
    inventoryQuantity;
    availableQuantityLink;
    installedQuantityLink;
    inventoryQuantityLink;
    inventoryMethod;
    get availableQuantityVhtml() {
        const abbreviatedInventoryMethodText = this.inventoryMethod
            ? this.abbreviatedInventoryMethod(this.inventoryMethod)
            : '';
        return this.formatValuesForHref(`${this.availableQuantity} ${abbreviatedInventoryMethodText}`, this.availableQuantityLink);
    }
    get installedQuantityVhtml() {
        const abbreviatedInventoryMethodText = this.inventoryMethod
            ? this.abbreviatedInventoryMethod(this.inventoryMethod)
            : '';
        return this.formatValuesForHref(`${this.installedQuantity} ${abbreviatedInventoryMethodText}`, this.installedQuantityLink);
    }
    get inventoryQuantityVhtml() {
        const abbreviatedInventoryMethodText = this.inventoryMethod
            ? this.abbreviatedInventoryMethod(this.inventoryMethod)
            : '';
        return this.formatValuesForHref(`${this.inventoryQuantity} ${abbreviatedInventoryMethodText}`, this.inventoryQuantityLink);
    }
    abbreviatedInventoryMethod(method) {
        return InventoryMethodDisplay.get(method) || InventoryUnitTypeDisplay.get(method);
    }
};
__decorate([
    Prop({
        default: '0'
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "availableQuantity", void 0);
__decorate([
    Prop({
        default: '0'
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "installedQuantity", void 0);
__decorate([
    Prop({
        default: '0'
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "inventoryQuantity", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "availableQuantityLink", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "installedQuantityLink", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "inventoryQuantityLink", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], AHDQuantityColumn.prototype, "inventoryMethod", void 0);
AHDQuantityColumn = __decorate([
    Component
], AHDQuantityColumn);
export default AHDQuantityColumn;
