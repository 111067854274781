var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, InventoryControl, ItemInstanceInventoryType, ItemMasterType, Side } from '~/db_types';
import { ItemMasterPropertyClass } from '~/db_types/swagger_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType } from '~/nasa_ui/types';
import { ChangeSetStatusDisplay } from '~/nasa_ui/types/enums/change-set';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { InventoryControlDisplay, ItemInstanceInventoryTypeDisplay, ItemMasterPropertyClassDisplay, SideDisplay } from '~/nasa_ui/types/enums/hardware';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { ItemInstancesTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { ItemInstanceResponseDisplay, transformEntitiesToTableFormat } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchItemInstance = class SearchItemInstance extends Mixins(BaseSearch, HttpMixin) {
    ChangeSetStatus = ChangeSetStatus;
    ChangeSetStatusDisplay = ChangeSetStatusDisplay;
    entityType = EntityType.ITEM_INSTANCE;
    EquipmentGroupDisplay = EquipmentGroupDisplay;
    SideDisplay = SideDisplay;
    hint = 'Drawing number, Description';
    inInventoryLocal = true;
    InventoryControl = InventoryControl;
    InventoryControlDisplay = InventoryControlDisplay;
    ItemInstanceInventoryType = ItemInstanceInventoryType;
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    ItemInstancesTableHeaders = ItemInstancesTableHeaders;
    ItemMasterType = ItemMasterType;
    placeholder = 'search for item instances';
    ItemMasterPropertyClass = ItemMasterPropertyClass;
    ItemMasterPropertyClassDisplay = ItemMasterPropertyClassDisplay;
    Side = Side;
    userSelectedMaxResults = 1000;
    searchFilters = {
        asBuiltNumber: null,
        drawingNumber: null,
        lotNumber: null,
        projectCode: null,
        serialNumber: null,
        side: null,
        subType: null
    };
    inInventory;
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('asBuiltNumber', 'item_drawings'), this.buildAppChipForProperty('drawingNumber', 'item_drawings'), this.buildAppChipForProperty('serialNumber', 'item_instances'), this.buildAppChipForProperty('lotNumber', 'item_instances'), this.buildAppChipForProperty('projectCode', 'item_instances'), this.buildAppChipForProperty('side', 'item_instances', SideDisplay), this.buildAppChipForProperty('equipmentGroup', 'item_masters', EquipmentGroupDisplay), this.buildAppChipForProperty('propertyClass', 'item_instances', ItemMasterPropertyClassDisplay), this.buildAppChipForProperty('inventoryControl', 'item_masters', InventoryControlDisplay), this.buildAppChipForProperty('itemMasterType', 'item_masters', EquipmentGroupDisplay), this.buildAppChipForProperty('size', 'item_instances'), this.buildAppChipForProperty('subType', 'item_instances', ItemInstanceInventoryTypeDisplay));
        if (this.inInventoryLocal === true) {
            appChips.push({
                text: 'Inventory Only',
                color: this.entityType,
                value: 'isInventory',
                outline: true,
                close: false // cant really put the 'x' here bc it doesnt tie DIRECTLY to searchFilters (it can be done in other ways)
            });
        }
        return appChips.filter((key) => key !== undefined);
    }
    get transformedSearchResponse() {
        if (!this.rawSearchResponse) {
            return [];
        }
        const transformed = transformEntitiesToTableFormat(EntityType.ITEM_INSTANCE, [...this.rawSearchResponse]);
        transformed.sort(sortObjectNumericallyBy('_updatedDateTimeTicks', true));
        return transformed;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                asBuiltNumber: this.searchFilters.asBuiltNumber ? [this.searchFilters.asBuiltNumber] : undefined,
                class: this.searchFilters.subType ?? undefined,
                drawingNumber: this.searchFilters.drawingNumber ? [this.searchFilters.drawingNumber] : undefined,
                hasManagedQuantity: this.inInventoryLocal || undefined,
                includeArchived: false,
                lotNumber: this.searchFilters.lotNumber ?? undefined,
                projectCode: this.searchFilters.projectCode ?? undefined,
                query: this.searchInput || undefined,
                serialNumber: this.searchFilters.serialNumber ?? undefined,
                side: this.searchFilters.side ?? undefined,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults
            };
            const resp = await this.httpGet('/hardware/item-instances', {
                config: {
                    params
                }
            });
            this.isLoading = false;
            this.rawSearchResponse = resp.data.results ? [...resp.data.results] : [];
            this.emitSearchPerformed();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    onMetaClickOfSearchResult(selectedItem) {
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    initializeInInventory() {
        this.inInventoryLocal = this.inInventory;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SearchItemInstance.prototype, "inInventory", void 0);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchItemInstance.prototype, "performSearch", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ItemInstanceResponseDisplay !== "undefined" && ItemInstanceResponseDisplay) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchItemInstance.prototype, "onClickOfSearchResult", null);
__decorate([
    Emit('metaRowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof ItemInstanceResponseDisplay !== "undefined" && ItemInstanceResponseDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SearchItemInstance.prototype, "onMetaClickOfSearchResult", null);
__decorate([
    Watch('inInventory', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchItemInstance.prototype, "initializeInInventory", null);
SearchItemInstance = __decorate([
    Component
], SearchItemInstance);
export default SearchItemInstance;
