var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types';
import { AssemblyTemplateType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { AlertType, EntityType, Maybe, SideDisplay } from '~/nasa_ui/types';
import { asBuiltNumberDisplay, serialNumberDisplay, sideDisplay } from '~/nasa_ui/utils/helpers/displays';
import { AssemblyTemplateTreeTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { buildIcon } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS } from '../constants/tablePagination';
import HttpMixin from '../mixins/HttpMixin';
export const transformAssemblyTemplateParts = (part) => {
    const _isItemMaster = Boolean(part.asBuiltNumber);
    const _isItemInstance = Boolean(part.serialNumber);
    let entityType;
    if (_isItemInstance) {
        entityType = EntityType.ITEM_INSTANCE;
    }
    else if (_isItemMaster) {
        entityType = EntityType.ITEM_MASTER;
    }
    else {
        entityType = EntityType.ITEM_DRAWING;
    }
    const _entityTypeIcon = buildIcon(entityType);
    const _description = part.description || DEFAULT_DASH;
    const _serialNumber = serialNumberDisplay(part?.serialNumber);
    let _asBuiltNumber;
    let _side;
    if (!part.asBuiltNumber) {
        _asBuiltNumber = NOT_APPLICABLE;
        _side = NOT_APPLICABLE;
    }
    else {
        _asBuiltNumber = asBuiltNumberDisplay(part.asBuiltNumber);
        _side = sideDisplay(part.side);
    }
    return {
        _asBuiltNumber,
        _description,
        _entityTypeIcon,
        _serialNumber,
        _side,
        ...part
    };
};
let BaseModalAssemblyTemplate = class BaseModalAssemblyTemplate extends Mixins(BaseModal, HttpMixin) {
    AssemblyTemplateTreeTableHeaders = AssemblyTemplateTreeTableHeaders;
    duplicateQueryResponse = null;
    parentData = null;
    selectedAssemblyTemplateParts = [];
    Side = Side;
    SideDisplay = SideDisplay;
    formData = {
        installedOn: Side.NONE,
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        pbsItemId: null,
        sequence: null,
        subType: AssemblyTemplateType.ASSEMBLY_TEMPLATE
    };
    ghostAssemblyTemplatePartToAdd = {
        description: '',
        side: Side.NONE,
        asBuiltNumber: null,
        drawingNumber: null,
        serialNumber: null
    };
    ghostAssemblyTemplatePartToAddAsBuilt = null;
    parentAssemblyTemplateId;
    get assemblyTemplatePartTableItems() {
        return this.selectedAssemblyTemplateParts.map(transformAssemblyTemplateParts);
    }
    get computedAssemblyTemplateType() {
        if (!this.parentData) {
            return null;
        }
        // Only exception for now is if ROOT_MEGA => CHILD_MEGA is what youre creating
        if (this.parentData.subType === AssemblyTemplateType.ROOT_MEGA) {
            return AssemblyTemplateType.CHILD_MEGA;
        }
        // Otherwise the parent subType will be the child subType
        return this.parentData.subType;
    }
    get computedAssemblyTemplateParts() {
        if (!this.selectedAssemblyTemplateParts) {
            return [];
        }
        return this.selectedAssemblyTemplateParts.map((part) => {
            return {
                attributes: { ...part.attributes },
                asBuiltNumber: part.asBuiltNumber,
                drawingNumber: part.drawingNumber ?? '',
                side: part.side ?? Side.NONE,
                serialNumber: part.serialNumber
            };
        });
    }
    get closeIcon() {
        return this.$icons['strong_close'];
    }
    get existingHardwareIds() {
        if (!this.selectedAssemblyTemplateParts) {
            return [];
        }
        return this.selectedAssemblyTemplateParts.map((part) => part.id);
    }
    get hasParent() {
        return Boolean(this.parentAssemblyTemplateId);
    }
    get installedOnMessage() {
        switch (this.formData.installedOn) {
            case Side.LEFT:
                return 'Left';
            case Side.RIGHT:
                return 'Right';
            case Side.NONE:
                return 'Not applicable';
        }
    }
    get isDuplicateBySequenceByParent() {
        const duplicateIds = this.duplicateQueryResponse?.nodes || [];
        return duplicateIds.length !== 0;
    }
    get isQuantityValid() {
        return this.isNotMoreThanMaxQuantity(this.formData.minQuantity, this.formData.maxQuantity);
    }
    get isSequenceValid() {
        return (Boolean(this.formData.sequence) &&
            !this.isDuplicateBySequenceByParent &&
            this.sequenceIsWithinSmallIntRange &&
            this.sequenceNumberIsInteger);
    }
    get isFormValid() {
        return this.isQuantityValid && this.isSequenceValid;
    }
    get largestSequenceNumber() {
        return this.parentData?.children?.reduce((acc, child) => (acc = acc > (child?.sequence || 0) ? acc : child?.sequence || 0), 0);
    }
    get parentName() {
        return this.parentData?.name ?? null;
    }
    get parentPbsItemId() {
        return this.parentData?.pbsItemId ?? null;
    }
    get parentSequence() {
        return this.parentData?.sequence ?? null;
    }
    get rowsPerPageItems() {
        return DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS;
    }
    get sequenceNumberIsInteger() {
        return Number.isInteger(Number(this.formData.sequence));
    }
    get sequenceIsWithinSmallIntRange() {
        // 32,767 is the maximum whole number value a SMALLINT data type can store.
        return (this.formData?.sequence || 0) < 32767;
    }
    get sequenceNumberErrorMessage() {
        if (!this.formData.sequence) {
            ['Sequence number is required'];
        }
        else if (this.isDuplicateBySequenceByParent) {
            return 'Sequence number exists.';
        }
        else if (!this.sequenceIsWithinSmallIntRange) {
            return 'Sequence number is out of range.';
        }
        else if (!this.sequenceNumberIsInteger) {
            return ['Sequence number must be integer'];
        }
        return null;
    }
    get sequenceNumberMessage() {
        if (this.isSequenceValid) {
            return 'Sequence accepted.';
        }
    }
    get shouldDisableAddGhostButton() {
        return !this.ghostAssemblyTemplatePartToAdd.drawingNumber;
    }
    get shouldShowAddPlaceholderButton() {
        return (Boolean(this.ghostAssemblyTemplatePartToAdd.drawingNumber) ||
            Boolean(this.ghostAssemblyTemplatePartToAdd.asBuiltNumber) ||
            Boolean(this.ghostAssemblyTemplatePartToAdd.serialNumber));
    }
    async createEntity(requestPayload) {
        if (!requestPayload) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Could not create the Assembly Template. No parent data found.'
            });
            return;
        }
        try {
            this.isSaving = true;
            await this.httpPost('/assembly-templates', requestPayload);
            this.isSaving = false;
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `${this.formData.name || 'Assembly Template'} created.`
            });
            this.emitRefreshSelfEvent();
            this.emitModalClose();
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not create the Assembly Template'
            });
            this.isSaving = false;
        }
    }
    deleteAssemblyTemplate(assemblyTemplateId) {
        return this.$http.delete(`/assembly-templates/${assemblyTemplateId}`);
    }
    async deleteEntity(assemblyTemplateId) {
        try {
            this.isSaving = true;
            await this.deleteAssemblyTemplate(assemblyTemplateId);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Deleted Assembly Template.`
            });
            this.emitAssemblyTemplateDeleted();
            this.emitRefreshParentEvent();
            this.emitModalClose();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not delete the Assembly Template'
            });
            this.isSaving = false;
        }
    }
    async fetchEntity(id) {
        try {
            const resp = await this.$http.get(`/assembly-templates/${id}`);
            return resp.data;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Parent Template'
            });
            this.isSaving = false;
            return null;
        }
    }
    async editAssemblyTemplate(assemblyTemplateId, editAssemblyTemplateVariables) {
        if (!editAssemblyTemplateVariables) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Could not edit the Assembly Template.'
            });
            return;
        }
        try {
            this.isSaving = true;
            await this.$http.put(`/assembly-templates/${assemblyTemplateId}`, editAssemblyTemplateVariables);
            const msg = `${this.formData.name ? this.formData.name : '<Shall remain nameless>'} edited.`;
            this.emitEntityEdited(msg).emitRefreshParentEvent().emitRefreshSelfEvent().closeDialog();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the Assembly Template'
            });
        }
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        return minQuantity <= maxQuantity;
    }
    onClickOfAddGhostAssemblyTemplatePart() {
        const _ghost = { ...this.ghostAssemblyTemplatePartToAdd };
        this.selectedAssemblyTemplateParts.push(_ghost);
        this.emitEntityEdited('Temporary Hardware added.');
    }
    onClickOfAssemblyTemplatePartTableItem(item) {
        const makeCompositeId = (item) => {
            return `${item.drawingNumber}${item.asBuiltNumber}${item.side}${item.serialNumber}`;
        };
        // Remove the clicked hardware
        this.selectedAssemblyTemplateParts = this.selectedAssemblyTemplateParts.filter((part) => makeCompositeId(part) !== makeCompositeId(item));
    }
    emitAssemblyTemplateDeleted() { }
    emitRefreshParentEvent() {
        if (this.$route.params.id) {
            this.$message(`reload_${this.$route.params.id}`);
        }
        return this;
    }
    emitRefreshSelfEvent() {
        return this;
    }
    // called from ui
    onClickOfSearchResult(result) {
        let asBuiltNumber = null;
        let side = null;
        let description = '';
        let serialNumber = '';
        let id = null;
        if ('serialNumber' in result) {
            serialNumber = result.serialNumber;
        }
        if ('asBuiltNumber' in result) {
            asBuiltNumber = result.asBuiltNumber;
            description = result.itemDrawingDescription ?? '';
            side = result.side;
        }
        else {
            description = result.description;
        }
        if ('id' in result) {
            id = result.id;
        }
        this.selectedAssemblyTemplateParts.push({
            asBuiltNumber,
            description,
            serialNumber,
            side,
            id,
            ...result
        });
    }
    onComputedAssemblyTemplateTypeChange() {
        if (this.computedAssemblyTemplateType) {
            this.formData.subType = this.computedAssemblyTemplateType;
        }
    }
    onChangeOfGhostAsBuilt(asBuilt) {
        if (!asBuilt) {
            this.ghostAssemblyTemplatePartToAdd.asBuiltNumber = null;
            this.ghostAssemblyTemplatePartToAdd.side = Side.NONE;
            return;
        }
        this.ghostAssemblyTemplatePartToAdd.asBuiltNumber = asBuilt.number;
        this.ghostAssemblyTemplatePartToAdd.side = asBuilt.side;
    }
    onChangeOfGhostDrawingNumber(drawingNumber) {
        if (!drawingNumber) {
            this.ghostAssemblyTemplatePartToAddAsBuilt = null;
        }
    }
    async onSequenceChange() {
        if (!this.formData.sequence) {
            return;
        }
        try {
            const resp = await this.checkForDuplicateViaAssemblyTemplate();
            this.duplicateQueryResponse = resp.data.assemblyTemplates;
        }
        catch (error) {
            this.duplicateQueryResponse = null;
        }
    }
    async onParentIdChange(parentAssemblyTemplateId) {
        if (!parentAssemblyTemplateId) {
            return;
        }
        const resp = await this.fetchEntity(parentAssemblyTemplateId);
        if (resp) {
            this.parentData = resp;
        }
    }
    checkForDuplicateViaAssemblyTemplate() {
        if (!this.parentAssemblyTemplateId) {
            throw new Error('Duplicate check requires parent id.');
        }
        return this.$apollo.query({
            query: gql `
        query CheckForAssemblyTemplateDuplicatesAssemblyTemplate($sequence: Int, $parentAssemblyTemplateId: UUID!) {
          assemblyTemplates(condition: { sequence: $sequence, parentId: $parentAssemblyTemplateId }) {
            nodes {
              id
              nodeId
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: {
                sequence: this.formData.sequence,
                parentAssemblyTemplateId: this.parentAssemblyTemplateId
            }
        });
    }
    emitModalClose() { }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], BaseModalAssemblyTemplate.prototype, "parentAssemblyTemplateId", void 0);
__decorate([
    Emit('assembly-template-deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "emitAssemblyTemplateDeleted", null);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "emitRefreshParentEvent", null);
__decorate([
    Emit('refresh-self'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "emitRefreshSelfEvent", null);
__decorate([
    Watch('computedAssemblyTemplateType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "onComputedAssemblyTemplateTypeChange", null);
__decorate([
    Watch('ghostAssemblyTemplatePartToAddAsBuilt'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "onChangeOfGhostAsBuilt", null);
__decorate([
    Watch('ghostAssemblyTemplatePartToAdd.drawingNumber'),
    Debounce(300),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "onChangeOfGhostDrawingNumber", null);
__decorate([
    Watch('formData.sequence'),
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], BaseModalAssemblyTemplate.prototype, "onSequenceChange", null);
__decorate([
    Watch('parentAssemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], BaseModalAssemblyTemplate.prototype, "onParentIdChange", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssemblyTemplate.prototype, "emitModalClose", null);
BaseModalAssemblyTemplate = __decorate([
    Component
], BaseModalAssemblyTemplate);
export default BaseModalAssemblyTemplate;
