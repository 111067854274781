var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseHardwareDisplay from '~/nasa_ui/base/BaseHardwareDisplay';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicLinkSlimFragment } from '~/nasa_ui/DAL/link';
import Hardware from '~/nasa_ui/modules/Hardware';
import { AlertType, EntityType, Maybe } from '~/nasa_ui/types';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/hardware';
let AppInventoryCrossContextDisplay = class AppInventoryCrossContextDisplay extends BaseHardwareDisplay {
    crossContextInventoryId;
    get inventoryMethod() {
        const hardwareData = this.hardwareData;
        return hardwareData?.itemMaster?.inventoryMethod || hardwareData?.itemMaster?.inventoryUnitType;
    }
    get _inventoryQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        this.hardwareData = this.hardwareData;
        return this.hardwareData.quantity.toString();
    }
    get _itemClassCrossContextDisplay() {
        const hwData = this.hardwareData;
        const subType = hwData?.subType;
        if (!subType) {
            return DEFAULT_DASH;
        }
        return ItemInstanceInventoryTypeDisplay.get(subType);
    }
    get _lotNumberCrossContextDisplay() {
        const hwData = this.hardwareData;
        const lot = hwData.itemInstanceCrossContext?.lotNumber;
        return this.$lotNumberDisplay(lot);
    }
    get _serialNumberCrossContextDisplay() {
        const hwData = this.hardwareData;
        const serial = hwData.itemInstanceCrossContext?.serialNumber;
        return this.$serialNumberDisplay(serial);
    }
    created() {
        this.$listen('reload', () => {
            this.fetchHardwareEntityCrossContext(this.crossContextInventoryId);
        });
    }
    fetchLinks() {
        const condition = this.linkCondtionForFetch;
        if (!condition) {
            return;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicLinkSlimFragment}

        query GetLinksForAhc($condition: LinkCondition!) {
          links(condition: $condition) {
            nodes {
              ...CosmicLinkSlim
            }
          }
        }
      `,
            variables: {
                condition
            }
        });
    }
    async fetchHardwareEntityCrossContext(crossContextInventoryId) {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          fragment ItemDrawingForAppHardwareCrossContextDisplay on ItemDrawing {
            availableQuantity
            description
            drawingNumber
            eeeIdentifier
            inventoryQuantity
            installedQuantity
            sizes
            nodeId
          }

          fragment ItemMasterForAppHardwareCrossContextDisplay on ItemMaster {
            asBuiltNumber
            availableQuantity
            contractEndingItemNumber
            criticalityCode
            drawingNumber
            federalSupplyClass
            initializationStatus
            installedQuantity
            inventoryMethod
            inventoryQuantity
            inventoryUnitType
            opsNom
            nodeId
            propertyClass
            side
            sizes
            equipmentGroup: subType
            itemDrawing {
              ...ItemDrawingForAppHardwareCrossContextDisplay
            }
          }

          fragment InventoryCrossContextForAppHardwareDisplay on InventoryCrossContext {
            asBuiltNumber
            drawingNumber
            hasChildren
            hasDifferences
            initialReceiptDate
            computedInstalledDate
            inventoryId
            isInstalled
            projectCode
            quantity
            side
            status
            subType
            supportOrganizationCode
            totalCost
            unitCost
            itemMaster {
              ...ItemMasterForAppHardwareCrossContextDisplay
            }
            itemInstanceCrossContext {
              asBuiltNumber
              description
              drawingNumber
              id
              lotNumber
              serialNumber
              side
              size
            }

            locationCrossContext {
              bin
              building
              room
              stock
            }
          }

          query GetGenericHardwareCrossContextForAppHardwareDisplay($inventoryIds: [UUID]) {
            getInventoriesCrossContext(inventoryIds: $inventoryIds) {
              nodes {
                ...InventoryCrossContextForAppHardwareDisplay
              }
            }
          }
        `,
                variables: {
                    inventoryIds: [crossContextInventoryId]
                }
            });
            if (!resp.data) {
                throw new Error('Couldnt fetch hardware');
            }
            if (resp.data.getInventoriesCrossContext?.nodes) {
                this.hardwareType = EntityType.INVENTORY;
                const invCrossContext = resp.data.getInventoriesCrossContext
                    .nodes[0];
                this.hardwareData = {
                    ...invCrossContext,
                    location: invCrossContext.locationCrossContext,
                    itemInstance: invCrossContext.itemInstanceCrossContext
                };
                this.isLoading = false;
            }
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to fetch Inventory for the hardware display.'
            });
        }
    }
    async populateLinkLinks() {
        if (this.linkCondtionForFetch) {
            try {
                const resp = await this.fetchLinks();
                if (resp && resp.data && resp.data.links) {
                    this.links = resp.data.links.nodes;
                }
            }
            catch (error) {
                this.$errorUtility({ err: error });
                this.$notification.add({
                    type: AlertType.ERROR,
                    text: 'Couldnt fetch links.'
                });
            }
        }
    }
    onCrossContextInventoryIdChange() {
        if (this.crossContextInventoryId) {
            this.fetchHardwareEntityCrossContext(this.crossContextInventoryId);
        }
    }
    onHardwareDataChange() {
        this.hardwareModule = new Hardware(this.hardwareData);
        this.populateLinkLinks();
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppInventoryCrossContextDisplay.prototype, "crossContextInventoryId", void 0);
__decorate([
    Watch('crossContextInventoryId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppInventoryCrossContextDisplay.prototype, "onCrossContextInventoryIdChange", null);
__decorate([
    Watch('hardwareData'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppInventoryCrossContextDisplay.prototype, "onHardwareDataChange", null);
AppInventoryCrossContextDisplay = __decorate([
    Component
], AppInventoryCrossContextDisplay);
export default AppInventoryCrossContextDisplay;
